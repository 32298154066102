<template>
  <!--That Succes Page it's last page after confirmation all thing-->
  <v-container
    fluid
    grid-list-xs
    fill-height
    justify-center
    align-center
    text-center
    color="bg"
  >
    <v-card width="90%" color="white" elevation-5 class="rounded-xl pa-10">
      <div>
        <v-img src="@/assets/img/tick.png" width="70%" class="mb-8 mx-auto" />
      </div>

      <div>
        <h6 class="grey--text text--lighten-1">
          Deine Bestellung war erfolgreich!
        </h6>
        <h2 class="py-4 font-weight-bold">20. Februar, DI 10:00 - 10:35</h2>
        <h6 class="grey--text text--lighten-1">
          Wirst du ca. deine Bestellung erhalten.
        </h6>
      </div>
      <v-card-actions>
        <v-btn to="/menu" elevation="0" color="primary" block class="mx-auto">
          Back to items
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  mounted() {
    this.order();
  },
  methods: {
    /**
     * load created order details
     */
    order() {
      this.$axios
        .get(ENDPOINTS.SELF_SERVICE.ORDERS.GET + this.$route.params.id)
        .then(res => {
          if (res.status === 200 && typeof res.data === "object") {
            localStorage.setItem("order", JSON.stringify(res.data));
            localStorage.setItem("ordered", JSON.stringify(res.data.items));
            this.$store.commit("Order/setOrder", res.data);
            this.$store.commit("Order/setOrdered", res.data.items);
            this.$store.commit("Order/clearCard");
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  data() {
    return {};
  }
};
</script>
